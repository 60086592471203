import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import HowItsWork from './components/HowItsWork'; 
import { Route, Routes } from 'react-router-dom';
import ResultAndInfo from './components/ResultAndInfo';
import GameStatic from './components/GameStatic';
 
 

function App() {
  return (
    <div className="App">
       <Navbar/>
       <div class='allPage'> 
       <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/how-its-work' element={<HowItsWork/>}/> 
        <Route path='/result-and-info' element={<ResultAndInfo/>}/>
        <Route path='/game-static' element={<GameStatic/>}/> 


        </Routes>  
       </div>
       
       <Footer/>
    </div>
  );
}

export default App;
