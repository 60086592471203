import React, { useEffect } from 'react'
import './style.css'
import { AiOutlineMenu } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {

    const navigate = useNavigate()

    useEffect(()=>{
        const nav = document.querySelector('.navbarMain');
        let lastScrollY = window.scrollY; 
        window.addEventListener('scroll',()=>{
          if(lastScrollY < window.scrollY){
            nav.classList.add('navbarMain--hidden');
          }else{
            nav.classList.remove('navbarMain--hidden');
          }  
          lastScrollY =window.scrollY 
        })  
    })
  return (
    <div class='navbarMain '>
        <nav class="navbar navbar-expand-lg " style={{width:'95%',margin:'auto',borderBottom:'0.5px solid #818181'}}>
            <div class="container-fluid">
                <a class="navbar-brand">
                    <img style={{height:'55px',cursor:'pointer'}} onClick={()=>navigate('/')} src={require('../images/navbar/jakpotLogo.png')} alt="" />
                </a>
                <button class='btn navMenuBtn' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasRight">
                <AiOutlineMenu/>
                </button>
                <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasRightLabel">
                    <div class="offcanvas-header">
                        <div>
                            <img style={{height:'55px'}} src={require('../images/navbar/jakpotLogo.png')} alt="" />
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                    <ul class="d-grid gap-3 px-0" style={{listStyle:'none'}}>
                            <li class="nav-item">
                            <a class="nav-link" onClick={()=>navigate('/')}>Home</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link" onClick={()=>navigate('/how-its-work')}>How it works</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link"   onClick={()=>navigate('/game-static')}>Game statistics</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link"   onClick={()=>navigate('/result-and-info')}>Results and info</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link"   onClick={()=>navigate('/')}>Contact</a>
                            </li>  
                            <li>
                                <button class='btn btn-primary px-4'>Play Online</button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="d-flex justify-content-end" id="navbarMenu">
                    <div> 
                        <ul class="navbar-nav gap-4">
                            <li class="nav-item">
                            <a class="nav-link"  onClick={()=>navigate('/')}>Home</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link"   onClick={()=>navigate('/how-its-work')}>How it works</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link"   onClick={()=>navigate('/game-static')}>Game statistics</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link"   onClick={()=>navigate('/result-and-info')}>Results and info</a>
                            </li>
                            <li class="nav-item">
                            <a class="nav-link"   onClick={()=>navigate('#')}>Contact</a>
                            </li> 
                            
                            <li>
                                <button class='btn btn-primary px-4'>Play Online</button>
                            </li>
                        </ul>
                    </div>
               
                </div>
            </div>
            </nav> 
    </div>
  )
}

export default Navbar