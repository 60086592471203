 import React, { useEffect, useRef, useState } from 'react'
import { Timer2 } from './Timer'
import { HomeOurBest } from './HomeGameSlider'
import AOS from 'aos';
import 'aos/dist/aos.css';

 const details = [
  {
    title :'playHuge',
    logo : `${require('.././images/home/playHuge.png')}`,
    newResult : <p>PowerBoll <br />Ticket Free</p>,
    drowDate:'May,30,2023',
    drowText :'Sunday, 12.03.2023',
    bonuCard: {
      bonusCode : 'CWBWEL200',
      img:`${require('.././images/howItsWork/g1.png')}`,
      star:'4.5',
      time:'next dow in 2 days',
      million:'30.4 million'
    },
    jackpot :'30.4 milllion',
    text:'Winnings on Mexico Melate may be subject to Mexican tax.',
    resultsTable:[
      {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
      {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
      {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
      {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
      {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
      {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
      {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
      {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
    ],
    lotteryAgents :{
      title :'<p>Our Best <br/> Lottery Agents</p>',
      text :'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ',
      cards:[
          {
             logo: `${require("../images/home/playHuge.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
              logo: `${require("../images/home/loot1.png")}`,
              title :'Powerball Ticket The big Jackpots',
              text :'On first Purchase',
              url:'#'
           },
           {
              logo: `${require("../images/home/loot2.png")}`,
              title :'Powerball Ticket The big Jackpots',
              text :'On first Purchase',
              url:'#'
           },
           {
              logo: `${require("../images/home/loot3.png")}`,
              title :'Powerball Ticket The big Jackpots',
              text :'On first Purchase',
              url:'#'
           },
           {
              logo: `${require("../images/home/playHuge.png")}`,
              title :'Powerball Ticket The big Jackpots',
              text :'On first Purchase',
              url:'#'
           },
           {
              logo: `${require("../images/home/loot1.png")}`,
              title :'Powerball Ticket The big Jackpots',
              text :'On first Purchase',
              url:'#'
           },
      ]
  },
  footerSection:{
      title:'How it work',
      text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
      img:`${require("../images/home/svg.png")}`,
      img2:`${require("../images/home/homeFImg.png")}`,
   }
  },
  {
   title :'loot1',
   logo : `${require('.././images/home/loot1.png')}`,
   newResult : <p>PowerBoll <br />Ticket Free</p>,
   drowDate:'May,30,2023',
   drowText :'Sunday, 12.03.2023',
   bonuCard: {
     bonusCode : 'CWBWEL200',
     img:`${require('.././images/howItsWork/g2.png')}`,
     star:'4.5',
     time:'next dow in 2 days',
     million:'30.4 million'
   },
   jackpot :'30.4 milllion',
   text:'Winnings on Mexico Melate may be subject to Mexican tax.',
   resultsTable:[
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
   ],
   lotteryAgents :{
     title :'<p>Our Best <br/> Lottery Agents</p>',
     text :'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ',
     cards:[
         {
            logo: `${require("../images/home/playHuge.png")}`,
            title :'Powerball Ticket The big Jackpots',
            text :'On first Purchase',
            url:'#'
         },
         {
             logo: `${require("../images/home/loot1.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
             logo: `${require("../images/home/loot2.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
             logo: `${require("../images/home/loot3.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
             logo: `${require("../images/home/playHuge.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
             logo: `${require("../images/home/loot1.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
     ]
 },
 footerSection:{
     title:'How it work',
     text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
     img:`${require("../images/home/svg.png")}`,
     img2:`${require("../images/home/homeFImg.png")}`,
  }
 },
 {
   title :'loot2',
   logo : `${require('.././images/home/loot2.png')}`,
   newResult : <p>PowerBoll <br />Ticket Free</p>,
   drowDate:'May,30,2023',
   drowText :'Sunday, 12.03.2023',
   bonuCard: {
     bonusCode : 'CWBWEL200',
     img:`${require('.././images/howItsWork/g3.png')}`,
      star:'4.5',
     time:'next dow in 2 days',
     million:'30.4 million'
   },
   jackpot :'30.4 milllion',
   text:'Winnings on Mexico Melate may be subject to Mexican tax.',
   resultsTable:[
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
   ],
   lotteryAgents :{
     title :'<p>Our Best <br/> Lottery Agents</p>',
     text :'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ',
     cards:[
         {
            logo: `${require("../images/home/playHuge.png")}`,
            title :'Powerball Ticket The big Jackpots',
            text :'On first Purchase',
            url:'#'
         },
         {
             logo: `${require("../images/home/loot1.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
             logo: `${require("../images/home/loot2.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
             logo: `${require("../images/home/loot3.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
             logo: `${require("../images/home/playHuge.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
             logo: `${require("../images/home/loot1.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
     ]
 },
 footerSection:{
     title:'How it work',
     text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
     img:`${require("../images/home/svg.png")}`,
     img2:`${require("../images/home/homeFImg.png")}`,
  }
 },
 {
   title :'loot3',
   logo : `${require('.././images/home/loot3.png')}`,
   newResult : <p>PowerBoll <br />Ticket Free</p>,
   drowDate:'May,30,2023',
   drowText :'Sunday, 12.03.2023',
   bonuCard: {
     bonusCode : 'CWBWEL200',
     img:`${require('.././images/howItsWork/g1.png')}`,
      star:'4.5',
     time:'next dow in 2 days',
     million:'30.4 million'
   },
   jackpot :'30.4 milllion',
   text:'Winnings on Mexico Melate may be subject to Mexican tax.',
   resultsTable:[
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
     {drowDate : 'friday,10.03.2023',jackpot:'M$ 30.3 million',drawnNumber:'220925',winner:'67854'},
   ],
   lotteryAgents :{
     title :'<p>Our Best <br/> Lottery Agents</p>',
     text :'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ',
     cards:[
         {
            logo: `${require("../images/home/playHuge.png")}`,
            title :'Powerball Ticket The big Jackpots',
            text :'On first Purchase',
            url:'#'
         },
         {
             logo: `${require("../images/home/loot1.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
             logo: `${require("../images/home/loot2.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
             logo: `${require("../images/home/loot3.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
             logo: `${require("../images/home/playHuge.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
          {
             logo: `${require("../images/home/loot1.png")}`,
             title :'Powerball Ticket The big Jackpots',
             text :'On first Purchase',
             url:'#'
          },
     ]
 },
 footerSection:{
     title:'How it work',
     text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
     img:`${require("../images/home/svg.png")}`,
     img2:`${require("../images/home/homeFImg.png")}`,
  }
 } 
 ]


 
 const ResultAndInfo = () => {
    const buttonName = details?.map((el)=>el.title)
    const buttonImg = details?.map((el)=>el.logo)  
    const [filterResult, setFilterResult] = useState(buttonName?.[0])
    const [data,setData] = useState() 

    const bonusCard = data?.[0].bonuCard
    const ref = useRef(null)
    const footerSection = data?.[0]?.footerSection
    useEffect(()=>{
      AOS.init({duration: 2000}); 
   },)

    useEffect(()=>{
      const image = document.querySelector('.svgDrow') 
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // Call your function here 
            setTimeout(()=>{
              image.classList.add('active')
            },800)
          }
        },
        { rootMargin: '0px', threshold: 0.5 }
      );
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    },[ref])


   const headerButton =()=>{ 
      let arr = []
      for(let i = 0 ; i< buttonImg?.length; i++){
         arr.push(
            <div class='resultHedButton' onClick={()=>setFilterResult(`${buttonName?.[i]}`)} style={filterResult == `${buttonName[i]}`?{ backgroundColor: '#FFC700'}:{ backgroundColor: '#F9F9FF'}} data-aos="fade-up">
               <img src={buttonImg?.[i]} alt="" /> 
            </div>
         )
      }
      return arr;
    } 

    const cardBonus = ()=>{ 
      return(
         <div class='rounded-4' style={{background:' #003F9D'}} >
            <br />
            <div class='d-flex justify-content-end'> 
               <div class='bg-danger p-1 px-3 rounded-4 fw-semibold text-white' style={{height:'fit-content',width:'fit-content',margin:'-40px 10%  0 0'}}>BONUS CONDE : {bonusCard?.bonusCode}</div>
            </div>
            <div class='d-flex align-items-center justify-content-end '  >
               <p class='fs-5 text-white' style={{marginRight:'5%'}}><span style={{color:'#FFC700'}}><i class="bi bi-star-fill"></i></span> <span clas='text-white'>{bonusCard?.star}</span></p>
            </div> 
            <div class='d-flex justify-content-between px-4'>
               <div>
                  <img style={{width:'150px'}} src={bonusCard?.img} alt="" />
               </div>
              
            </div>
               <div class='text-start'>
                  <div class='px-4'> 
                     <p class='fs-5'style={{color:'#508AE2',fontWeight:'bold'}}>{bonusCard?.time}</p>
                     <p class='fs-1 text-white fw-semibold'>{bonusCard?.million}</p>
                     <br />
                  </div>
               </div>
         </div>
      )
    }

    const table = () =>{
      return(
         <table class="table mt-1" style={{borderColor:'white',border:'10px solid white',borderRight:'20px solid white',borderLeft:'20px solid white' }} data-aos="zoom-in">
         <thead>
             <tr style={{background:'white',color:'gray'}}>
                 <th style={{borderBottom:'1px solid #F9F9FF',fontWeight:'500'}} class='fs-5 text-nowrap p-3 text-center'>Drow date</th>
                 <th style={{borderBottom:'1px solid #F9F9FF',fontWeight:'500'}} class='fs-5 text-nowrap p-3 text-center'>Jackpot</th>
                 <th style={{borderBottom:'1px solid #F9F9FF',fontWeight:'500'}} class='fs-5 text-nowrap p-3 text-center'>drawn numbers</th> 
                 <th style={{borderBottom:'1px solid #F9F9FF',fontWeight:'500'}} class='fs-5 text-nowrap p-3 text-center'>of the winners</th> 
             </tr>
         </thead>
         <div class='p-0 m-0' />
         <tbody  >
             {
                 data?.[0]?.resultsTable?.map((el)=>{
                     return(
                         <tr style={{borderColor:'#5289BF',border:'10px solid white',borderRight:'20px solid white',borderLeft:'20px solid white' ,color:'black'}} >
                             <td class=' text-center fw-semibold fs-5   '><p class='mt-3'>{el.drowDate}</p></td>
                             <td class=' text-center fw-semibold fs-5 '><p class='mt-3'>{el.jackpot}</p></td>
                             <td class=' text-center fw-semibold fs-5 '>
                              <div class='px-2 p-1 m-auto' style={{minWidth:'300px', backgroundImage:`url(${require("../images/home/timerBg.png")})`, backgroundSize:'100% 100%', backgroundRepeat:'no-repeat'}}>
                              <div class='d-flex gap-1 justify-content-center' style={{width:'90%',margin:'auto'}}>
                                          <div> 
                                             <div class='d-flex gap-1'>
                                                <input type="text" class="form-control fs-3 fw-bold text-center" value={el.drawnNumber[0]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
                                                <input type="text" class="form-control fs-3 fw-bold text-center" value={el.drawnNumber[1]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
                                             </div> 
                                          </div>  
                                          <div> 
                                             <div class='d-flex gap-1'>
                                                <input type="text" class="form-control fs-3 fw-bold text-center" value={el.drawnNumber[2]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
                                                <input type="text" class="form-control fs-3 fw-bold text-center" value={el.drawnNumber[3]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
                                             </div>
                                             
                                          </div>  
                                          <div> 
                                             <div class='d-flex gap-1'>
                                                <input type="text" class="form-control fs-3 fw-bold text-center" value={el.drawnNumber[4]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
                                                <input type="text" class="form-control fs-3 fw-bold text-center" value={el.drawnNumber[5]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
                                             </div> 
                                          </div>
                                    </div>
                                 </div>
                             </td>
                             <td class=' text-center fw-semibold fs-5 '><p class='mt-3'>{el.winner}</p></td> 
                         </tr>
                     )
                 })
             } 
         </tbody>
     </table>
      )
    }


    
    const ourBest =()=>{
      let arr = []
      for(let i = 0 ; i < data?.[0]?.lotteryAgents?.cards.length ; i++){
          arr.push(

          <div  style={{width:'300px', margin:'auto'}}>
              <div>
                  <div class='d-flex  p-2 justify-content-center align-items-center m-auto' style={{borderRadius:"50%",height:'230px',width:'230px', background:'#FFC700'}} >
                      <img style={{width:'200px',height:'70px'}} src={data?.[0].lotteryAgents?.cards[i].logo} alt="" />
                  </div>
              </div>
              <div>
                  <p class='fs-5 fw-bold p-2'>{data?.[0].lotteryAgents?.cards[i].title}</p>
                  <p class='my-3'>{data?.[0].lotteryAgents?.cards[i].text}</p>
                  <button class='btn btn-primary mb-2'>Know More</button> 
              </div>
          </div>
          )
      }
      return arr;
  }

  console.log(data?.[0].lotteryAgents?.cards);
      

    useEffect(()=>{
      const data = details?.filter((el)=>el.title == filterResult)
      setData(data)
    },[filterResult])
   return (
     <div>
      <div class='col-11 m-auto d-flex gap-4' style={{overflowX:'auto'}}> 
         {headerButton()}
      </div>
      <div class='col-11 m-auto' style={{background:'#F9F9FF'}}>
         <div class='row gap-5 '>
            <div class='col-lg-6 col-12 text-start'>
               <div class='col-11 m-auto'> 
                  <p class='fw-semibold fs-5' style={{color:'gray'}} data-aos="fade-up">new results</p>
                  <p class='fs-1 text-primary fw-bold' style={{color:'#1785CA'}} data-aos="fade-up">{data?.[0].newResult}</p>
                  <p data-aos="fade-up">Draw Date {data?.[0].drowText}</p>
                  <div class='p-3 mt-3'data-aos="fade-up" style={{width:'fit-content', backgroundImage:`url(${require("../images/home/timerBg.png")})`, backgroundSize:'100% 100%', backgroundRepeat:'no-repeat'}}>
                     <Timer2 date={data?.[0].drowDate} />
                  </div>
               </div>
            </div>
            <div class='col-lg-5  col-12 '>
               <div class='col-lg-10 col-sm-8 col-12 rounded-5 m-auto mt-4' style={{border:'20px solid white'}} data-aos="zoom-in"> 
                  {cardBonus()}
               </div>
            </div> 
         </div>
      </div>

      <div class='col-11 m-auto text-start p-4'  style={{background:'#F9F9FF'}}> 
            <p class='fw-semibold fs-5' style={{color:'gray'}} data-aos="fade-up">Jackpot</p>
            <p class='fs-1 fw-semibold text-danger' data-aos="fade-up">{data?.[0].jackpot}</p> 
            <p class='fw-semibold mt-4' data-aos="fade-up">{data?.[0].text}</p>
            <div class='d-flex justify-content-between align-items-center p-3 flex-wrap gap-3' style={{background:'white', borderRadius:'10px 10px 0 0'}} data-aos="zoom-in-up">
                  <div class='d-flex align-items-center'>
                     <p class='fs-5' style={{color:'blue', fontWeight:'bold'}}>
                         Most recent Results
                     </p> 
                  </div>
                  <div class='d-flex align-items-center gap-2 text-start'>
                     <input type="month" class='form-control' />
                     <button class='btn btn-primary'>Apply </button>
                  </div>
            </div>
            <div style={{overflowX:'auto'}}>
               {table()}
            </div>
      </div>

      <div style={{background: 'linear-gradient(180deg, #FFFFFF 0% , #EFEFFF 88.75%)'}}> 
         <div class='my-5 p-4'>
            <p class='display-3 fw-bold' dangerouslySetInnerHTML={{ __html: data?.[0].lotteryAgents.title }} data-aos="fade-up"></p>
            <p class='fs-5 m-auto' style={{maxWidth:'550px'}} dangerouslySetInnerHTML={{ __html: data?.[0].lotteryAgents.text }} data-aos="fade-up"></p>
         </div>
         <div class='my-5' data-aos="fade-up">
               <HomeOurBest data={ourBest()}/> 
               <br /><br />
         </div>
      </div>
      <div class='mt-5' ref={ref}>
            <div class='col-11 m-auto rounded-5 row' style={{background:'linear-gradient(101.58deg, #4141C6 0.3%, #212187 86.4%)'}}>
               <div class='col-md-6 col-12 text-start'>
                     <div class='px-4 mt-4'>
                        <p class='display-4 fw-bold text-white' data-aos="fade-up">{footerSection?.title}</p>
                        <p class='text-white col-10 my-3' data-aos="fade-up">{footerSection?.text}</p>
                     </div>
                     <div class='p-3'>
                        <img class='svgDrow' id='svgDrow' style={{width:'100%'}} src={footerSection?.img} alt="" />
                     </div>
               </div>
               <div class='col-md-6 col-12'> 
                  <img style={{width:'100%'}} data-aos="zoom-in"  src={footerSection?.img2} alt="" />
               </div>
            </div>   
      </div>
         
     </div>
   )
 }
 
 export default ResultAndInfo