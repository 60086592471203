import React, { useEffect, useRef, useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {HomeGameSlider, HomeOurBest, TopBonuses} from './HomeGameSlider';
import Player from './Player';
import Timer from './Timer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Lottie from './Lottie';
import Line from './Line';


// export function sliceIntoChunks(arr: Array<any>, chunkSize = 7) {
//     const res = [];
//     for (let i = 0; i < arr.length; i += chunkSize) {
//         const chunk = arr.slice(i, i + chunkSize);
//         res.push(chunk);
//     }
//  return res;
// }
 

const homeAip = {
    homeSliderLarg : [
        {
            backgroundImage : `${require('../images/home/homeSlider.png')}`,
            title :<p>Global <br /> Online Game</p>,
            text :'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
            url :'#'
        }, 
    ],

    section2 : {
        timer : { 
            timerBg:`${require("../images/home/timerBg.png")}`,
            title:'Next Draw remaining time'
        }, 
        title :`<p>Start  Buying <br /> your favorite lottery</p>`,
        text:`<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntu<p/>`,
        img1: `${require("../images/home/coins.png")}`,
        img2: `${require("../images/home/jackpotImg.png")}` 
    },

    section3 : [
        {
            cardLogo :`${require("../images/home/playHuge.png")}`,
            bonusCode :'CWBWEL200',
            title :'Powerball Ticket Free 1',
            text :'On first Purchase',
            star:"4.3",
            welcomBonus :'150% / INR 15000',
            wagering :'30 X (B)',
            paymentMethod : [
                {
                    cardImg :`${require("../images/home/card1.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card2.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card3.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card4.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card5.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card6.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card7.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card8.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card9.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card10.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card11.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card12.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card13.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card14.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card15.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card16.png")}`,
                    url:"#"
                }, 
            ],
            readMore :'Since 1998, Playhugelottos is offering players worldwide Lotteries and chance win the big Jackpots International',
            url:"#"
        }, 
        {
            cardLogo :`${require("../images/home/loot1.png")}`,
            bonusCode :'CWBWEL200',
            title :'Powerball Ticket Free 2',
            text :'On first Purchase',
            star:"4.3",
            welcomBonus :'150% / INR 15000',
            wagering :'30 X (B)',
            paymentMethod : [
                {
                    cardImg :`${require("../images/home/card1.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card2.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card3.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card4.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card5.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card6.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card7.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card8.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card9.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card10.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card11.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card12.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card13.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card14.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card15.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card16.png")}`,
                    url:"#"
                }, 
            ],
            readMore :'Since 1998, Playhugelottos is offering players worldwide Lotteries and chance win the big Jackpots International',
            url:"#"
        },
        {
            cardLogo :`${require("../images/home/loot2.png")}`,
            bonusCode :'CWBWEL200',
            title :'Powerball Ticket Free 3',
            text :'On first Purchase',
            star:"4.3",
            welcomBonus :'150% / INR 15000',
            wagering :'30 X (B)',
            paymentMethod : [
                {
                    cardImg :`${require("../images/home/card1.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card2.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card3.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card4.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card5.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card6.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card7.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card8.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card9.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card10.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card11.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card12.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card13.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card14.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card15.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card16.png")}`,
                    url:"#"
                }, 
            ],
            readMore :'Since 1998, Playhugelottos is offering players worldwide Lotteries and chance win the big Jackpots International',
            url:"#"
        },
        {
            cardLogo :`${require("../images/home/loot3.png")}`,
            bonusCode :'CWBWEL200',
            title :'Powerball Ticket Free 4',
            text :'On first Purchase',
            star:"4.3",
            welcomBonus :'150% / INR 15000',
            wagering :'30 X (B)',
            paymentMethod : [
                {
                    cardImg :`${require("../images/home/card1.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card2.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card3.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card4.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card5.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card6.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card7.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card8.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card9.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card10.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card11.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card12.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card13.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card14.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card15.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card16.png")}`,
                    url:"#"
                }, 
            ],
            readMore :'Since 1998, Playhugelottos is offering players worldwide Lotteries and chance win the big Jackpots International',
            url:"#"
        }, 
        {
            cardLogo :`${require("../images/home/playHuge.png")}`,
            bonusCode :'CWBWEL200',
            title :'Powerball Ticket Free 5',
            text :'On first Purchase',
            star:"4.3",
            welcomBonus :'150% / INR 15000',
            wagering :'30 X (B)',
            paymentMethod : [
                {
                    cardImg :`${require("../images/home/card1.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card2.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card3.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card4.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card5.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card6.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card7.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card8.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card9.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card10.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card11.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card12.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card13.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card14.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card15.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card16.png")}`,
                    url:"#"
                }, 
            ],
            readMore :'Since 1998, Playhugelottos is offering players worldwide Lotteries and chance win the big Jackpots International',
            url:"#"
        }
    ],

    section4 :{
        backgroundImage : `${require('../images/home/homePoster.png')}`,
        img :`${require('../images/home/homePosterpic.png')}`,
            title :'<p>Play / Buy <br/> lottery now</p>',
            text :'<p>Lorem ipsum dolor sit amet, consectetur <br/>adipiscing elit, sed do eiusmod tempor incididunt ut </p> ',
            url :'#'
    },

    section5 :{
        backgroundImage :`${require('../images/home/subtract.png')}`,
        title :'<p>Enter Now for <br/> a chance to win</p>',
        url :'#'
    },

    section6 :{
        title :"<p>Our favorite <br/> Online lottery <br/> Providers</p>",
        text :'<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntut<p/>',
        url:'#',
        slider : [
            {
                img : `${require('../images/home/play1.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play2.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play3.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play4.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play5.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play1.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play2.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play3.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play4.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play5.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play1.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play2.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play3.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play4.png')}`,
                url :'#'
            },
            {
                img : `${require('../images/home/play4.png')}`,
                url :'#'
            },

        ]
    },
    section7 :{
        title :'<p>Our Best <br/> Lottery Agents</p>',
        text :'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ',
        cards:[
            {
               logo: `${require("../images/home/playHuge.png")}`,
               title :'Powerball Ticket The big Jackpots',
               text :'On first Purchase',
               url:'#'
            },
            {
                logo: `${require("../images/home/loot1.png")}`,
                title :'Powerball Ticket The big Jackpots',
                text :'On first Purchase',
                url:'#'
             },
             {
                logo: `${require("../images/home/loot2.png")}`,
                title :'Powerball Ticket The big Jackpots',
                text :'On first Purchase',
                url:'#'
             },
             {
                logo: `${require("../images/home/loot3.png")}`,
                title :'Powerball Ticket The big Jackpots',
                text :'On first Purchase',
                url:'#'
             },
             {
                logo: `${require("../images/home/playHuge.png")}`,
                title :'Powerball Ticket The big Jackpots',
                text :'On first Purchase',
                url:'#'
             },
             {
                logo: `${require("../images/home/loot1.png")}`,
                title :'Powerball Ticket The big Jackpots',
                text :'On first Purchase',
                url:'#'
             },
        ]
    },

    section8 : [
        {
            img : `${require("../images/home/offer1.png")}`,
            url : '#'
        },
        {
            img : `${require("../images/home/offer2.png")}`,
            url : '#'
        },
        {
            img : `${require("../images/home/offer3.png")}`,
            url : '#'
        },
        {
            img : `${require("../images/home/offer1.png")}`,
            url : '#'
        },
        {
            img : `${require("../images/home/offer2.png")}`,
            url : '#'
        },
    ],
    section9:{
        title:'How it work',
        text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
        img:`${require("../images/home/svg.png")}`,
        img2:`${require("../images/home/homeFImg.png")}`,
    }



}



const homeSlider = homeAip.homeSliderLarg
const section2 = homeAip.section2
const section3 = homeAip.section3
const section4 = homeAip.section4
const section5 = homeAip.section5
const section6 = homeAip.section6
const section6Slider= section6.slider
const section7 = homeAip.section7
const section7Cards = homeAip.section7.cards
const section8 = homeAip.section8
const section9 = homeAip.section9
 

function createArrayOfObjects(arr, subLength) {
    const result = [];
    for (let i = 0; i < arr.length; i += subLength) {
      result.push(arr.slice(i, i + subLength).map((val) => ( val )));
    }
    return result;
  }

 
const subLength = 6;
const ourFavriteCard = createArrayOfObjects(section6Slider, subLength); 

 
const Home = () => {

    const [cardDiv,setCardDiv] = useState({})
    const [cardReadMore,setCardReadMore] = useState({})

      
    const gameCard =()=>{  
        let arr = []
        for(let i = 0 ; i < section3.length; i++) {
            arr.push( 
                <div class='homeGameCard m-auto'>
                <div class='d-flex align-items-center justify-content-center' style={{height:'150px',borderRadius:'38px 38px 0 0',backgroundColor:'#F9F9FF'}}>
                    <img src={section3[i].cardLogo} alt="" />
                </div>
                <div style={{borderRight:'0.5px solid #606098', borderLeft:'0.5px solid #606098'}}>
                    <div class='d-flex justify-content-end px-2'> 
                        <div class='px-2 rounded-5' style={{backgroundColor:"#FF5530",width:'fit-content',marginTop:'-10px',fontSize:"12px"}}>
                            <p class='p-1 text-white fw-semibold'><span>BONUS CODE :</span> <span>{section3[i].bonusCode}</span> </p>
                        </div>
                    </div>
                    <div class='row p-2'>
                        <div class='col-9 text-start '>
                            <p class='text-primary fs-4 fw-bold '>{section3[i].title}</p>
                            <p class='' style={{fontSize:'14px'}}>{section3[i].text}</p>
                        </div>
                        <div class='col-3'>
                            <div class='d-flex align-items-center'>
                                <img style={{height:'16px'}} src={require("../images/home/Star.png")} alt="" />
                                <p class=' px-2'>{section3[i].star}</p>
                            </div>
                        </div>
                    </div>

                    <div class='d-flex p-2 gap-2'>
                        <div class='text-start'>
                            <p style={{fontSize:'8px',margin:'4px 2px'}}>WELCOME BONUS</p>
                            <div style={{background:'#F0F0FE',borderRadius:'4px'}}>
                                <p class=' px-2 '>{section3[i].welcomBonus}</p>
                            </div>
                        </div>
                        <div class='text-start'>
                            <p style={{fontSize:'8px',margin:'4px 2px'}}>WAGERING</p>
                            <div style={{background:'#F0F0FE',borderRadius:'4px'}}>
                                <p class='px-2 '>{section3[i].wagering}</p>
                            </div>
                        </div> 
                    </div>

                    <div class='p-2'>
                        <div class='text-start'>
                                <p style={{fontSize:'8px',margin:'4px 2px'}}>WELCOME BONUS</p>
                                <div class='row'>
                                    <div class='col-8 d-flex justify-content-between align-items-center'>
                                        {section3[i].paymentMethod?.map((el,i)=>{
                                            if(i < 4){ 
                                                return( 
                                                    <img style={{width:'34px',cursor:'pointer',border:'0.2px solid #d5d5e1'}} src={el.cardImg} alt="" /> 
                                                )
                                            }
                                           
                                        })}  
                                    </div>
                                    <div class='col-4 d-flex justify-content-between align-items-center dropdown ' style={{position:'relative'}}>
                                            <p class='text-primary fw-semibold '  style={{fontSize:'14px',cursor:'pointer'}} onMouseEnter={()=>setCardDiv({[section3[i].title]:true})} onMouseLeave={()=>setCardDiv({[section3[i].title]:false})} >View More</p>
                                           {
                                            !cardDiv[section3[i].title] ? "" :
                                            <div class='cardDive p-2 rounded-3 bg-light border' style={{width:'fit-content' ,display:'grid', gridTemplateColumns:'23% 23% 23% 23%', gap:'8px',position:'absolute',top:'20px', left:'-150px' ,zIndex:'99999'}} onMouseEnter={()=>setCardDiv({[section3[i].title]:true})} onMouseLeave={()=>setCardDiv({[section3[i].title]:false})} >
                                                {
                                                    section3[i].paymentMethod.map((el)=>{
                                                        return(
                                                            <div >
                                                                <img style={{width:'45px', cursor:'pointer'}} src={el.cardImg}/>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                
                                            </div>
                                           }
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class='d-flex align-items-center justify-content-around' style={{height:'80px',backgroundColor:'#F9F9FF',borderRadius:"0 0 8px 8px", border:'.5px solid #606098 ',position:'relative'}}>
                        <div class='d-flex align-items-center'>
                            <p class='fw-semibold' style={{color:"#FF5530"}} onMouseEnter={()=>setCardReadMore({[section3[i].title]:true})} onMouseLeave={()=>setCardReadMore({[section3[i].title]:false})}>Read More</p>
                            {
                                !cardReadMore[section3[i].title] ? "" :
                            <div class='rounded p-2 text-start bg-light border' style={{width:'250px',position:'absolute',top:'50px',}} onMouseEnter={()=>setCardReadMore({[section3[i].title]:true})} onMouseLeave={()=>setCardReadMore({[section3[i].title]:false})}>
                                <p  style={{color:'gray'}}>{section3[i].readMore}</p>
                            </div>
                            }
                        </div>
                        <div class='d-flex align-items-center'>
                            <button class='btn btn-primary px-3 fw-semibold'>Play Online</button>
                        </div>
                </div>
            </div>
            )
       
        } 
      
        return arr;
    }

    const gamePlay =()=>{
        let arr = []
        for(let i = 0 ; i < section6.slider.length ; i++ ){
            arr.push(
                <div class='d-grid'>
                    <div class='border' style={{width:'170px', height:'165px'}}>

                    </div>
                    <div class='border' style={{width:'170px', height:'165px'}}>

                    </div> 
                </div>
            )
        }
    }
    const ourBest =()=>{
        let arr = []
        for(let i = 0 ; i < section7.cards.length ; i++){
            arr.push(

            <div  style={{width:'300px', margin:'auto'}}>
                <div>
                    <div class='d-flex  p-2 justify-content-center align-items-center m-auto' style={{borderRadius:"50%",height:'230px',width:'230px', background:'#FFC700'}} >
                        <img style={{width:'200px',height:'70px'}} src={section7?.cards[i].logo} alt="" />
                    </div>
                </div>
                <div>
                    <p class='fs-5 fw-bold p-2'>{section7?.cards[i].title}</p>
                    <p class='my-3'>{section7?.cards[i].text}</p>
                    <button class='btn btn-primary mb-2'>Know More</button>

                </div>
            </div>
            )
        }
        return arr;
    }

    const topBonuses =()=>{
        let arr =[]
        for(let i = 0 ; i <section8?.length ; i++ ){
            arr.push(
                <div class=' '>
                    <div class='col-md-10 col-12 m-auto'>
                        <img style={{width:'90%',height:'200px'}} src={section8[i].img} alt="" />
                    </div>

                </div>
            )
        }
        return arr;
    }
    
    useEffect(()=>{
        AOS.init({duration: 2000}); 
    },)
    
    const ref = useRef(null)

    useEffect(()=>{
        const image = document.querySelector('.svgDrow') 
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              // Call your function here 
              setTimeout(()=>{
                image.classList.add('active')
              },800)
            }
          },
          { rootMargin: '0px', threshold: 0.5 }
        );
        if (ref.current) {
          observer.observe(ref.current);
        }
        return () => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        };
      },[ref])
   
  return (
    <div> 
        <div >     
            {/* --------------first section------------------- */}
            <div class='my-4' style={{width:'95%', margin:'auto',borderRadius:'20px'}}>
            <Carousel infiniteLoop={true}swipeable={false} showArrows={false} autoPlay={true} showThumbs= {false} stopOnHover={false} showStatus={false} showIndicators={false}>
               {
                homeSlider?.map((el)=>{
                    return (
                        <div class='text-start homeTopCarousel  ' style={{background:'linear-gradient(4.92deg, #262693 -55.97%, #3A3AB8 81.77%, #3B3BBA 90.81%)',borderRadius:'40px'}}>
                            <div class='row ' > 
                                <div class='col-lg-6 col-11 m-auto' >
                                    <div style={{maxWidth:'450px', margin:'5%'}}>
                                        <br />
                                        <p class='display-3 fw-bold text-white'  data-aos="fade-up">{el.title}</p>
                                        <p class='text-white' dangerouslySetInnerHTML={{ __html: el.text }} data-aos="fade-up"/>  
                                        <button type="button" class="btn btn-light px-3 fw-semibold my-3" data-aos="fade-up">Play Online</button>
                                    </div>
                                </div>
                                <div class='col-lg-6 col-11 m-auto'>
                                    <img src={el.backgroundImage} alt=""  data-aos="zoom-in"/> 
                                </div>
                            </div>
                        </div>
                    )
                })
               }
            </Carousel>
            </div>
            {/* --------------second section---------------- */}
            <div style={{position:'relative', top:'-125px'}} data-aos="zoom-in-up">
                <div class='px-2'>
                    <div class='p-2 py-4' style={{maxWidth:'550px', margin:'auto', backgroundImage:`url(${section2.timer.timerBg})`, backgroundSize:'100% 100%', backgroundRepeat:'no-repeat'}}>
                            <p class='fs-3 fw-semibold mt-3'>{section2.timer.title}</p>
                            {/* <div class='d-flex gap-2 justify-content-center' style={{width:'90%',margin:'auto'}}>
                                <div> 
                                    <div class='d-flex gap-1'>
                                        <input type="text" class="form-control fs-3 fw-bold" value={2} readOnly maxLength={1} style={{maxWidth:'50px'}} />
                                        <input type="text" class="form-control fs-3 fw-bold" value={2} readOnly maxLength={1} style={{maxWidth:'50px'}} />
                                    </div>
                                    <div class='text-start'>DAYS</div>
                                </div>

                                <p class='fs-5 fw-bold mt-2'>:</p>

                                <div> 
                                    <div class='d-flex gap-1'>
                                        <input type="text" class="form-control fs-3 fw-bold" value={0} readOnly maxLength={1} style={{maxWidth:'50px'}} />
                                        <input type="text" class="form-control fs-3 fw-bold" value={9} readOnly maxLength={1} style={{maxWidth:'50px'}} />
                                    </div>
                                    <div class='text-start'>HOURS</div>
                                </div>


                                <p class='fs-5 fw-bold mt-2'>:</p>

                                <div> 
                                    <div class='d-flex gap-1'>
                                        <input type="text" class="form-control fs-3 fw-bold" value={2} readOnly maxLength={1} style={{maxWidth:'50px'}} />
                                        <input type="text" class="form-control fs-3 fw-bold" value={5} readOnly maxLength={1} style={{maxWidth:'50px'}} />
                                    </div>
                                    <div class='text-start'>MINUTES</div>
                                </div>
                            </div> */}
                            <Timer date={"May,30,2023"} />

                             
                    </div>
                </div>
                <div class='row mt-4' style={{width:'95%', margin:'auto'}}>
                    <div class='col-md-3 col-12 d-grid align-items-center justify-content-center'>
                        <img src={section2.img1} alt="" data-aos="fade-right"/>
                    </div>
                    <div class='col-md-6 col-12 d-grid align-items-center justify-content-center'  >
                        <div style={{width:'90%',margin:'auto'}}>  
                            <p class='display-3 fw-bold' style={{color:'#4e4e98'}}  dangerouslySetInnerHTML={{ __html: section2.title }}data-aos="fade-up"/> 
                            <p class='fs-5 m-auto'  dangerouslySetInnerHTML={{ __html: section2.text }}data-aos="fade-up"/> 
                        </div>
                    </div>
                    <div class='col-md-3 col-12 d-grid align-items-center justify-content-center'>
                        <img src={section2.img2} alt="" data-aos="fade-left"/>
                    </div> 
                </div>
            </div>
{/* -----------section 3 ------------------------ */}
                <div style={{width:'95%', margin:'auto'}}> 
                    <div class=' ' data-aos="fade-up">

                        <HomeGameSlider data={gameCard()}/> 
                    </div>
                </div>
{/* ----------section 4 --------------- */}

               <div style={{backgroundImage:`url(${section4.backgroundImage})`, backgroundRepeat:'no-repeat',backgroundSize:'cover',borderRadius:'30px',width:'95%', margin:'auto'}}>
                    <div class='row align-items-center
                    
                    '>
                        <div class='col-lg-6 col-12' data-aos="zoom-in">
                            {/* <img style={{width:'100%'}} src={section4.img} alt="" /> */}
                            <div class='col-md-8 col-10 m-auto my-4'>

                                <div style={{position:'relative' }}> 
                                    <Lottie/>
                                    {/* <Player path={require('../animation/roulette.json')}/> */}
                                {/* <div style={{position:'absolute',top:'0',left:'auto',height:'300px',width:'100%'}}> 
                                    <Player path={require('../animation/rupeeCoin.json')}  />
                                </div>  */}
                                </div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-12 text-start'> 
                                <div style={{maxWidth:'450px', margin:'10%'}}>
                                    <p class='display-3 fw-bold' style={{color:'#4e4e98'}}  dangerouslySetInnerHTML={{ __html: section4.title }} data-aos="fade-up"/> 
                                    <p class=' ' style={{color:'#4e4e98'}} dangerouslySetInnerHTML={{ __html: section4.text }} data-aos="fade-up"/>  
                                    <button type="button" class="btn btn-primary px-3 mt-3 fw-semibold" data-aos="fade-up">Play Online</button>
                                </div>
                        </div>
                    </div>
                    {/* ---------------section 5 ------------------- */}
                    <div class='d-flex align-items-center justify-content-center ' style={{backgroundImage:`url(${section5.backgroundImage})`, backgroundRepeat:'no-repeat',backgroundSize:'100% 100%', height:'450px'}} data-aos="zoom-in">
                            <div>
                                <p class='text-white  display-1 fw-bold'  dangerouslySetInnerHTML={{ __html: section5.title }} data-aos="fade-up"/>
                                <button class='btn btn-warning px-3 my-4 fw-semibold  'data-aos="fade-up">Play Online</button>
                            </div>
                    </div>
               </div>

{/*-------------sction 6 --------------------------  */}
               <div class='row col-11 m-auto my-5'>
                    <div class='col-md-5 col-12 text-start mt-3'>
                        <p class='display-3 fw-bold' dangerouslySetInnerHTML={{ __html: section6.title }}data-aos="fade-up" />
                        <p class='fs-5' style={{maxWidth:'350px'}} dangerouslySetInnerHTML={{ __html: section6.text }} data-aos="fade-up"/>
                        <button class='btn btn-primary px-3 my-3' data-aos="fade-up">Explore More</button>
                    </div>
                    <div class='col-md-7 col-12 ourFavoritGameSection' data-aos="fade-up">  
                        <Carousel axis={'vertical'} autoPlay={true} infiniteLoop={true} centerMode={true} showArrows={false}  showStatus={false} swipeable={true} > 
                        {
                            ourFavriteCard.map((el)=>{
                                return( 
                                    <div class='row gap-4 justify-content-around p-4'>
                                        {el.map((e)=>{
                                            return(
                                                <div class="ourFavoritGame border rounded-3 col-md-3 col-5">
                                                    <img class="ourFavoritImage" style={{width:'100%'}} src={e.img} alt="" />
                                                    <div class="ourFavoritMiddle">
                                                        <button class='btn btn-warning p-2  fw-semibold  '>Play now</button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    )
                                })
                            }
                            </Carousel>
                          
                        <Carousel/>  
                    </div>

               </div>

               {/* ---------------section 7-------------- */}
               <div style={{background: 'linear-gradient(180deg, #FFFFFF 0% , #EFEFFF 88.75%)'}}> 
                    <div class='my-5 p-4'>
                        <p class='display-3 fw-bold' dangerouslySetInnerHTML={{ __html: section7.title }} data-aos="fade-up"></p>
                        <p class='fs-5 m-auto' style={{maxWidth:'550px'}} dangerouslySetInnerHTML={{ __html: section7.text }} data-aos="fade-up"></p>
                    </div>
                    <div class='my-5' data-aos="fade-up">
                         <HomeOurBest data={ourBest()}/>
                         <br /><br />
                    </div>
               </div>

               {/* ----------------section 6 ----------- */}
               <div>
                    <div class='col-11 m-auto' data-aos="fade-up"> 
                        <TopBonuses topBonuses={topBonuses()}/>
                    </div> 
               </div>
            
                <div class='mt-5' ref={ref}>
                    <div class='col-11 m-auto rounded-5 row' style={{background:'linear-gradient(101.58deg, #4141C6 0.3%, #212187 86.4%)'}}>
                        <div class='col-md-6 col-12 text-start'>
                            <div class='px-4 mt-4'>
                            <p class='display-4 fw-bold text-white' data-aos="fade-up">{section9.title}</p>
                            <p class='text-white col-10 my-3' data-aos="fade-up">{section9.text}</p>
                            </div>
                            <div class='p-3'>
                            <img class='svgDrow' id='svgDrow' style={{width:'100%'}} src={section9.img} alt="" />
                            </div>
                        </div>
                        <div class='col-md-6 col-12'> 
                            <img style={{width:'100%'}} data-aos="zoom-in"  src={section9.img2} alt="" />
                        </div>
                    </div>   
                </div>
             
        </div> 
    </div>
  )
}

export default Home