import React, { useEffect, useRef } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const game = {
    topImg:`${require("../images/staticGame/staticGameBg.png")}`,
    topTitle:'Game Static',
    text:'Best Game to play fan tan Live 2023',
    games : [
        {
            cardLogo :`${require("../images/home/playHuge.png")}`,
            bonusCode :'CWBWEL200',
            title :'Powerball Ticket Free',
            text :'On first Purchase',
            points:['Paytm & Crypto payments','Live Dealer Teen Patti & Andar Bahar','₹500 min. deposit!'],
            star:"4.3",
            welcomBonus :'150% / INR 15000',
            wagering :'30 X (B)',
            paymentMethod : [
                {
                    cardImg :`${require("../images/home/card1.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card2.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card3.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card4.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card5.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card6.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card7.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card8.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card9.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card10.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card11.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card12.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card13.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card14.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card15.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card16.png")}`,
                    url:"#"
                }, 
            ],
            readMore :'Since 1998, Playhugelottos is offering players worldwide Lotteries and chance win the big Jackpots International',
            url:"#"
        }, 
        {
            cardLogo :`${require("../images/home/loot1.png")}`,
            bonusCode :'CWBWEL200',
            title :'Powerball Ticket Free ',
            text :'On first Purchase',
            points:['Paytm & Crypto payments','Live Dealer Teen Patti & Andar Bahar','₹500 min. deposit!'],
            star:"4.3",
            welcomBonus :'150% / INR 15000',
            wagering :'30 X (B)',
            paymentMethod : [
                {
                    cardImg :`${require("../images/home/card1.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card2.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card3.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card4.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card5.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card6.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card7.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card8.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card9.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card10.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card11.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card12.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card13.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card14.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card15.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card16.png")}`,
                    url:"#"
                }, 
            ],
            readMore :'Since 1998, Playhugelottos is offering players worldwide Lotteries and chance win the big Jackpots International',
            url:"#"
        },
        {
            cardLogo :`${require("../images/home/loot2.png")}`,
            bonusCode :'CWBWEL200',
            title :'Powerball Ticket Free',
            text :'On first Purchase',
            points:['Paytm & Crypto payments','Live Dealer Teen Patti & Andar Bahar','₹500 min. deposit!'],
            star:"4.3",
            welcomBonus :'150% / INR 15000',
            wagering :'30 X (B)',
            paymentMethod : [
                {
                    cardImg :`${require("../images/home/card1.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card2.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card3.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card4.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card5.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card6.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card7.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card8.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card9.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card10.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card11.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card12.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card13.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card14.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card15.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card16.png")}`,
                    url:"#"
                }, 
            ],
            readMore :'Since 1998, Playhugelottos is offering players worldwide Lotteries and chance win the big Jackpots International',
            url:"#"
        },
        {
            cardLogo :`${require("../images/home/loot3.png")}`,
            bonusCode :'CWBWEL200',
            title :'Powerball Ticket Free',
            text :'On first Purchase',
            points:['Paytm & Crypto payments','Live Dealer Teen Patti & Andar Bahar','₹500 min. deposit!'],
            star:"4.3",
            welcomBonus :'150% / INR 15000',
            wagering :'30 X (B)',
            paymentMethod : [
                {
                    cardImg :`${require("../images/home/card1.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card2.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card3.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card4.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card5.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card6.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card7.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card8.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card9.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card10.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card11.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card12.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card13.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card14.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card15.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card16.png")}`,
                    url:"#"
                }, 
            ],
            readMore :'Since 1998, Playhugelottos is offering players worldwide Lotteries and chance win the big Jackpots International',
            url:"#"
        }, 
        {
            cardLogo :`${require("../images/home/playHuge.png")}`,
            bonusCode :'CWBWEL200',
            title :'Powerball Ticket Free',
            text :'On first Purchase',
            points:['Paytm & Crypto payments','Live Dealer Teen Patti & Andar Bahar','₹500 min. deposit!'],
            star:"4.3",
            welcomBonus :'150% / INR 15000',
            wagering :'30 X (B)',
            paymentMethod : [
                {
                    cardImg :`${require("../images/home/card1.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card2.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card3.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card4.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card5.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card6.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card7.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card8.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card9.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card10.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card11.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card12.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card13.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card14.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card15.png")}`,
                    url:"#"
                },
                {
                    cardImg :`${require("../images/home/card16.png")}`,
                    url:"#"
                }, 
            ],
            readMore :'Since 1998, Playhugelottos is offering players worldwide Lotteries and chance win the big Jackpots International',
            url:"#"
        }
    ],
    footerSection:{
        title:'How it work',
        text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
        img:`${require("../images/home/svg.png")}`,
        img2:`${require("../images/home/homeFImg.png")}`,
     }
}
const gameSections= game?.games
const gameSection =()=>{
    let arr = []
    for(let i = 0 ; i < gameSections?.length; i++){
        arr.push(
            <div class='my-3  rounded-5 border' style={{overflowX:'auto'}} data-aos="fade-up">
                <div class='d-flex gap-3 align-items-center'>
                    <div  style={{ background:'#F9F9FF' }}>

                    <div style={{minWidth:'250px',background:'#F9F9FF',minHeight:'200px'}} class='d-flex align-items-center justify-content-center'>
                        <img style={{width:'200px'}} src={gameSections[i].cardLogo} alt="" />
                    </div>
                    </div>
                    <div style={{minWidth:'300px'}}>
                        <div class='row'>
                            <div class='col-9'><p class=' fs-3 text-start text-primary fw-bold'>{gameSections[i]?.title}</p> </div>
                            <div class='col-3 '>
                                <p class='d-flex align-item-center gap-2 fs-5 '><span style={{color:'#FFC700'}}><i class="bi bi-star-fill"></i></span><span>{gameSections?.[i].star}</span></p>
                            </div>
                            <p class='text-start fs-5'>{gameSections[i].text}</p>
                            {
                                gameSections[i].points.map((el)=>{
                                    return(
                                        <p class='text-start fw-bold' style={{fontSize:'12px'}}><span class='text-success'><i class="bi bi-check-square-fill"></i></span> <span>{el}</span></p>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div style={{minWidth:'300px'}}>
                        <p style={{background:'#FF5530',padding:'2px 10px',width:'fit-content',borderRadius:'10px',color:'white'}} >BONUS CODE: {gameSections[i].bonusCode}</p>
                        <br />
                        <div class='d-flex gap-2 '>
                            <div class='text-start'>
                                <p style={{fontSize:'12px',margin:'4px 2px'}}>WELCOME BONUS</p>
                                <div style={{background:'#F0F0FE',borderRadius:'4px'}}>
                                    <p class=' px-3 p-1 '>{gameSections[i].welcomBonus}</p>
                                </div>
                            </div>
                            <div class='text-start'>
                                <p style={{fontSize:'12px',margin:'4px 2px'}}>WAGERING</p>
                                <div style={{background:'#F0F0FE',borderRadius:'4px'}}>
                                    <p class='px-3 p-1 '>{gameSections[i].wagering}</p>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div style={{minWidth:'250px'}}>
                        <p class='text-start' style={{fontSize:'14px',margin:'4px 2px'}}>PAYMENT METHODS</p>
                        <div class='row'> 
                                {
                                    gameSections[i].paymentMethod.map((el)=>{
                                        return(
                                            <div class='col-3 px-2 p-1 '>
                                                <img style={{width:'45px', cursor:'pointer'}} src={el.cardImg}/>
                                            </div>
                                        )
                                    })
                                } 
                        </div> 
                    </div>
                    <div class='d-grid p-3 justify-content-around ' style={{minWidth:'150px'}}>
                        <p class='text-danger' style={{cursor:'pointer'}}>Read More</p><br />
                        <button class='btn btn-primary'>Play Online</button>
                    </div>
                </div> 
            </div>
        )
    }
    return arr;
}

const GameStatic = () => {

    useEffect(()=>{
        AOS.init({duration: 2000}); 
    },[])
    const ref = useRef(null)
    useEffect(()=>{
        const image = document.querySelector('.svgDrow') 
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              // Call your function here 
              setTimeout(()=>{
                image.classList.add('active')
              },1000)
            }
          },
          { rootMargin: '0px', threshold: 0.5 }
        );
        if (ref.current) {
          observer.observe(ref.current);
        }
        return () => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        };
      },[ref])

  return (
    <div>
        <div class='col-11 m-auto d-flex justify-content-center align-items-center' style={{backgroundImage:`url(${game.topImg})`,backgroundSize:'cover',height:'350px',borderRadius:'35px' }} data-aos="zoom-in">
            <p class='display-2 fw-bold text-white' data-aos="fade-up">{game.topTitle}</p>
        </div>
        <div class='col-11 m-auto  '>
            <p class='text-start fs-1 fw-bold my-4' style={{color:'#F35727'}} data-aos="fade-up" >{game.text}</p>
            {gameSection()}
        </div>

        <div class='mt-5' ref={ref}>
            <div class='col-11 m-auto rounded-5 row' style={{background:'linear-gradient(101.58deg, #4141C6 0.3%, #212187 86.4%)'}}>
               <div class='col-md-6 col-12 text-start'>
                     <div class='px-4 mt-4'>
                        <p class='display-4 fw-bold text-white' data-aos="fade-up">{game?.footerSection?.title}</p>
                        <p class='text-white col-10 my-3' data-aos="fade-up">{game?.footerSection?.text}</p>
                     </div>
                     <div class='p-3'>
                        <img class='svgDrow' id='svgDrow' style={{width:'100%'}} src={game?.footerSection?.img} alt="" />
                     </div>
               </div>
               <div class='col-md-6 col-12'> 
                  <img style={{width:'100%'}} data-aos="zoom-in"  src={game?.footerSection?.img2} alt="" />
               </div>
            </div>   
      </div>
    </div>
  )
}

export default GameStatic