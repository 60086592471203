 import React, { useEffect, useState } from 'react'
 
 const Lottie = () => {
    const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        setScrollPos(window.pageYOffset);
      };
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const rotateDeg = scrollPos % 360;
  
    const imageStyle = {
      transform: `rotate(${rotateDeg}deg)`,
      width:'100%',
    

    };
   return ( 
      <img
      src={require('.././images/home/scrollWinn.png')}
      style={imageStyle}
    />
 
   )
 }
 
 export default Lottie