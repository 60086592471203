import React, { useEffect } from 'react'
import { AiFillTwitterCircle } from 'react-icons/ai'
import { FaFacebook, FaInstagramSquare } from 'react-icons/fa'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';


const Footer = () => {

    const navigate = useNavigate()

    useEffect(()=>{
        AOS.init({duration: 2000}); 
    })

  return (
    <div style={{background:'linear-gradient( #ffffff 10%, #EFEFFF 88.75%)' }}>
        <br />
        <br />
        <div class='col-11 row m-auto '>
            <div class='col-lg-5 col-12 text-start p-3'>
                <img src={require("../images/navbar/jakpotLogo.png")} alt="" data-aos="fade-up"/>
                <br />
                <p style={{color:'gray',maxWidth:'300px'}} data-aos="fade-up">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
            </div>
            <div class='col-lg-7 col-12 row'>
                <div class='col-md-6 col-12 text-start p-3 ' data-aos="fade-up">
                    <p class='fw-bold px-1'>Quick Links</p>
                    <div class='row p-3'> 
                        <p class='col-6'  onClick={()=>navigate('/')}>Home</p>
                        <p class='col-6'  onClick={()=>navigate('/how-its-work')}>How it works</p>
                        <p class='col-6'  onClick={()=>navigate('/game-static')}>Game statistics</p>
                        <p class='col-6'  onClick={()=>navigate('/result-and-info')}>Results and info</p>
                        <p class='col-6'  onClick={()=>navigate('#')}>Contact</p> 
                    </div>
                </div>
                <div class='col-md-6 col-12'>
                    <div class='col-12 m-auto'>
                        <img style={{width:'227px', height:'204px'}} src={require("../images/navbar/footerImg.png")} alt="" data-aos="zoom-in" /> 
                    </div>
                </div>
            </div>
        </div>
        <div class='col-11 row m-auto'>
            <div class='d-flex gap-3'  data-aos="zoom-in"> 
                <div><AiFillTwitterCircle class='fs-1' style={{cursor:'pointer'}}/></div>
                <div><FaInstagramSquare class='fs-1' style={{cursor:'pointer'}}/></div>
                <div><FaFacebook class='fs-1' style={{cursor:'pointer'}}/></div>
            </div>
                
            <hr class='mt-3' />
        </div>
        <div class='col-11 d-flex align-items-center gap-4  m-auto flex-wrap text-start'>
            <p>Terms & Conditions</p>
            <p>Privacy Policy</p>
            <p>Cookie Policy</p>
            <p>Copyright © 2023 Hi lab Solution</p> 
        </div>
        <br />
    </div>
  )
}

export default Footer