import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const HowItsWork = () => {

    useEffect(()=>{
        AOS.init({duration: 2000}); 
    },[])
 

  return (
    <div>
        <div class='col-11 m-auto rounded-5 steps3' style={{background:'linear-gradient(101.58deg, #4141C6 0.3%, #212187 86.4%)',height:'100%'}}>
            <br />
            <div class='col-md-6 col-10 text-start' style={{margin:'5% '}}>
                <p class='display-3 text-white fw-bold'>How it works</p>
                <p class='fs-5 col-md-8 col-11' style={{color:'#8687E3'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
            </div>
            <div class='howItsWork'>
                <div class='d-flex howItsWork1' data-aos="fade-up" data-aos-duration="1000" data-aos-easing="linear" >
                    <div>
                        <p class='fs-2 text-white fw-bold'>Step 1</p>
                        <p class='text-white' style={{maxWidth:'250px'}}>OLorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    </div>
                    <div>
                        <img style={{height:'100%'}} src={require('../images/howItsWork/Group.png')} alt="" />
                    </div>
                </div>
                <div class='d-flex howItsWork2' data-aos="fade-up" data-aos-duration="2000" data-aos-easing="linear" >
                    <div>
                        <p class='fs-2 text-white fw-bold'>Step 2</p>
                        <p class='text-white' style={{maxWidth:'250px'}}>OLorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    </div>
                    <div>
                        <img style={{height:'100%'}} src={require('../images/howItsWork/Group1.png')} alt="" />
                    </div>
                </div> 
                <div class='d-flex howItsWork3' data-aos="fade-up" data-aos-duration="2500"data-aos-easing="linear" >
                    <div>
                        <p class='fs-2 text-white fw-bold'>Step 3</p>
                        <p class='text-white' style={{maxWidth:'250px'}}>OLorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    </div>
                    <div>
                        <img style={{height:'100%'}} src={require('../images/howItsWork/Group2.png')} alt="" />
                    </div>
                </div> 

            </div>

        </div>

        <div class='col-11 m-auto rounded-5 mt-5' style={{background:'linear-gradient(101.58deg, #4141C6 0.3%, #212187 86.4%)',height:'100%'}}>
            <br />
            <div class='col-md-6 col-10 text-start' style={{margin:'5%'}}>
                <p class='display-3 text-white fw-bold'>How it works</p>
                <p class='fs-5 col-md-8 col-11' style={{color:'#8687E3'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
            </div>
            <div class=''>
               <div class='d-flex flex-wrap justify-content-around gap-5 '>
                <div data-aos="zoom-in">
                    <div style={{width: '300px',height: '314px',background: 'rgba(249, 249, 255, 0.11)',border:' 1px solid #C8C8D8',backdropFilter: 'blur(20.5px)',borderRadius: '18px'}}>
                        <img src={require('../images/howItsWork/g1.png')} alt="" />
                    </div> 
                    <p class='fs-4 fw-bold text-white mt-2'>Step 1</p>
                    <p class='fs-5 text-white'>Place your bet on 1, 2, 3, or 4.</p> 
                </div>
                <div data-aos="zoom-in">
                    <div style={{width: '300px',height: '314px',background: 'rgba(249, 249, 255, 0.11)',border:' 1px solid #C8C8D8',backdropFilter: 'blur(20.5px)',borderRadius: '18px'}}>
                        <img src={require('../images/howItsWork/g2.png')} alt="" />
                    </div> 
                    <p class='fs-4 fw-bold text-white mt-2'>Step 1</p>
                    <p class='fs-5 text-white'>Place your bet on 1, 2, 3, or 4.</p> 
                </div>
                <div data-aos="zoom-in">
                    <div style={{width: '300px',height: '314px',background: 'rgba(249, 249, 255, 0.11)',border:' 1px solid #C8C8D8',backdropFilter: 'blur(20.5px)',borderRadius: '18px'}}>
                        <img src={require('../images/howItsWork/g3.png')} alt="" />
                    </div> 
                    <p class='fs-4 fw-bold text-white mt-2'>Step 1</p>
                    <p class='fs-5 text-white'>Place your bet on 1, 2, 3, or 4.</p> 
                </div>
               </div> 
               <br /><br />
            </div>

        </div>
        
    </div>
  )
}

export default HowItsWork