import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; 

const responsiveGameSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1200, min: 650 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1
    }
     
  };

  export   const HomeGameSlider = ({data}) => {
    const responsiveGameSlider = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6
      },
      desktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 4
      },
      tablet: {
        breakpoint: { max: 1200, min: 650 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 650, min: 0 },
        items: 1
      }
       
    };
    
  return (
    <Carousel responsive={responsiveGameSlider}  infinite={true}>
        {data?.map((el)=>{
            return(
                <>
                
                {el}
                <br />
                <br />
                <br />
                <br />
                <br />
                </>
            )
        })}
     </Carousel>
  )
}

 

export const homePlaySlider =({data})=>{ 
  const responsiveGameSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1200, min: 650 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1
    }
     
  };
    return (
        <Carousel responsive={responsiveGameSlider}  infinite={true}>
            {data?.map((el)=>{
                return(
                    <>
                    {el}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    </>
                )
            })}
         </Carousel>
      )
}



export const HomeOurBest =({data})=>{ 
  const responsiveGameSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1200, min: 650 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1
    }
     
  };
  return (
      <Carousel responsive={responsiveGameSlider}  swipeable={true}  infinite={true}   showDots={true} arrows={false} >
          {data?.map((el)=>{
              return(
                  <>
                  {el}
                  <br />
                 
                  </>
              )
          })}
       </Carousel>
    )
}

export const TopBonuses =({topBonuses})=>{ 
  const responsiveGameSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1200, min: 650 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1
    }
     
  };
  return (
      <Carousel responsive={responsiveGameSlider}  swipeable={true}  infinite={true}   showDots={false} arrows={true} >
          {topBonuses?.map((el)=>{
              return(
                  <>
                  {el}
                  <br />
                 
                  </>
              )
          })}
       </Carousel>
    )
}