import React, { useEffect, useState } from "react";

const Timer = ({date}) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [mins, setMinutes] = useState(0);
  const [secs, setSecs] = useState(0); 
  const deadline = date; 
  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSecs(Math.floor((time / 1000) % 60));
  }; 
  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, [deadline]);

  const day = days < 10 ? "0" +`${days}` : `${days}`
  const hour = hours < 10 ? "0" + `${hours}` : `${hours}`
  const min = mins < 10 ? "0" + `${mins}` : `${mins}`
  const sec = secs < 10 ? "0" + `${secs}` : `${secs}` 
  return (
    <div class='d-flex gap-2 justify-content-center' style={{width:'90%',margin:'auto'}}>
      <div> 
          <div class='d-flex gap-1'>
              <input type="text" class="form-control fs-3 fw-bold text-center" value={day[0]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
              <input type="text" class="form-control fs-3 fw-bold text-center" value={day[1]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
          </div>
          <div class='text-start'>DAYS</div>
      </div> 
      <p class='fs-5 fw-bold mt-2'>:</p> 
      <div> 
          <div class='d-flex gap-1'>
              <input type="text" class="form-control fs-3 fw-bold text-center" value={hour[0]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
              <input type="text" class="form-control fs-3 fw-bold text-center" value={hour[1]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
          </div>
          <div class='text-start'>HOURS</div>
      </div>


      <p class='fs-5 fw-bold mt-2'>:</p>

      <div> 
          <div class='d-flex gap-1'>
              <input type="text" class="form-control fs-3 fw-bold text-center" value={min[0]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
              <input type="text" class="form-control fs-3 fw-bold text-center" value={min[1]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
          </div>
          <div class='text-start'>MINUTES</div>
      </div>
  </div>
  );
};

export default Timer;


export const Timer2 = ({date}) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [mins, setMinutes] = useState(0);
  const [secs, setSecs] = useState(0); 
  const deadline = date;  
  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSecs(Math.floor((time / 1000) % 60));
  }; 
  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, [deadline]);

  const day = days < 10 ? "0" +`${days}` : `${days}`
  const hour = hours < 10 ? "0" + `${hours}` : `${hours}`
  const min = mins < 10 ? "0" + `${mins}` : `${mins}`
  const sec = secs < 10 ? "0" + `${secs}` : `${secs}` 
  return (
    <div class='d-flex gap-1 justify-content-center' style={{width:'90%',margin:'auto'}}>
      <div> 
          <div class='d-flex gap-1'>
              <input type="text" class="form-control fs-3 fw-bold text-center" value={day[0]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
              <input type="text" class="form-control fs-3 fw-bold text-center" value={day[1]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
          </div> 
      </div>  
      <div> 
          <div class='d-flex gap-1'>
              <input type="text" class="form-control fs-3 fw-bold text-center" value={hour[0]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
              <input type="text" class="form-control fs-3 fw-bold text-center" value={hour[1]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
          </div>
           
      </div>  
      <div> 
          <div class='d-flex gap-1'>
              <input type="text" class="form-control fs-3 fw-bold text-center" value={min[0]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
              <input type="text" class="form-control fs-3 fw-bold text-center" value={min[1]} readOnly maxLength={1} style={{maxWidth:'50px'}} />
          </div> 
      </div>
  </div>
  );
};

 
